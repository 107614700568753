<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form">
        <span class="error-header p-b-45"> 404 </span>
        <span class="error-subheader p-b-5"> Похоже, Ты Заблудился </span>
        <span class="error-subheader2 p-b-5">
          Страница, Которую Вы Ищете, Недоступна!
        </span>
        <div class="container-login100-form-btn p-t-30">
          <button
            mat-flat-button
            color="primary"
            class="login100-form-btn"
            (click)="submit()"
          >
            Перейти На Главную Страницу
          </button>
        </div>
        <!-- <div class="w-full p-t-15 p-b-15 text-center">
          <div>
            <a href="#" class="txt1"> Need Help? </a>
          </div>
        </div> -->
      </form>
      <div
        class="login100-more"
        style="background-image: url('assets/images/fon.jpeg')"
      ></div>
    </div>
  </div>
</div>
