import { Store } from './user';

export class Dictionary {
  id: number = null;
  code: string = null;
  nameKz: string = null;
  nameQq: string = null;
  nameRu: string = null;
  nameEn: string = null;
  referenceId?: number = null;
}

export class Person {
  address: string = null;
  email: string = null;
  firstName: string = null;
  id: number = null;
  lastName: string = null;
  middleName: string = null;
  telephone: string = null;
  type?: { id: number; name: string; type: string } = null;
  fullName: string = null;
}

export class ExchangeRate {
  id: number = null;
  actualDate: string = null;
  value: string | number = null;
  currency: Dictionary = null;
}

export class Product {
  actualPrice: number = null;
  color: Dictionary = null; // цвет
  description: string = null; // примечание
  id: number = null;
  imei: string = ''; // имей код
  memory: Dictionary = null; // память
  model: Dictionary = null; // модель
  productPostings: Posting[] = [];
  family: Dictionary = null; //семейство
  ram: Dictionary = null; //
  state: string = null;
  status: string = null;
  store: Store = null;
  provider: Dictionary = null;
  product_type: string = null;
  isDuty: boolean = false;
  price: number = null;
  currency: Dictionary = null;
}

export class Posting {
  currency: Dictionary = null;
  description: string = null;
  id: number = null;
  isDuty: boolean = false;
  price: number = null;
  productId: number = null;
  provider: Person = null;
}
