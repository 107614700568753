import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { ApiConfig } from '../../../api.config';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { INTERCEPTOR } from '../../admin/model/constants';
import { NotificationService } from 'src/app/shared/services/notification.service';
// import { NotificationService } from 'src/app/shared/services/notification.service';
// import { ToastrService } from 'ngx-toastr';
// import { INTERCEPTOR } from 'src/app/admin/model/constants';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private router: Router,
    private ntf: NotificationService // private toastr: ToastrService
  ) {}

  addAuthHeader(request: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
  }

  setTokens(token: any): any {
    localStorage.setItem('access_token', token.access_token);
    localStorage.setItem('refresh_token', token.refresh_token);
    return token;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes(ApiConfig.api + '/oauth/token')) {
      return next.handle(request);
    }

    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('username');
          this.router.navigate(['/signin'], {
            queryParams: {
              from: INTERCEPTOR,
              status: '401',
            },
          });
          return throwError(error);
        } else if (error.status === 500) {
          this.ntf.showNotification(
            'snackbar-danger',
            'Ошибка на стороне сервера обратитесь в тех.поддержку'
          );
        } else if (error.status === 400) {
          if (error.error) {
            if (error.error.errorCode === 'product-not-found') {
              this.ntf.showNotification('snackbar-danger', 'Продукт не найден');
              return;
            } else if (error.error.errorCode === 'product-not-found') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Продукт нет в наличии'
              );
              return;
            } else if (error.error.errorCode === 'product-not-sold') {
              this.ntf.showNotification('snackbar-danger', 'Продукт не продан');
            } else if (error.error.errorCode === 'currency_course_not_found') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Курс валюты не найден'
              );
              return;
            } else if (
              error.error.errorCode === 'product-post-info-not-found'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Информация о продукте на найдено'
              );
              return;
            } else if (error.error.errorCode === 'dic_currency_not_found') {
              this.ntf.showNotification('snackbar-danger', 'Валюта не найдена');
              return;
            } else if (
              error.error.errorCode === 'product-imei-not-sold-more-than-one'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Продукт не был продан более чем один раз'
              );
              return;
            } else if (
              error.error.errorCode === 'store-balance-less-than-reques'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Запрашиваемая сумма больше фактической'
              );
              return;
            } else if (error.error.errorCode === 'order_not_found') {
              this.ntf.showNotification('snackbar-danger', 'Заказ не найден');
              return;
            } else if (error.error.errorCode === 'unique_issue') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Ошибка в уникальности объекта'
              );
              return;
            } else if (error.error.errorCode === 'not_nullable') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Параметр не может быть пустым'
              );
              return;
            } else if (
              error.error.errorCode ===
              'client-duty-can-repayment-only-owner-store-or-admin'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Погашать задолженность клиента могут только пользователи этого магазина или админы'
              );
              return;
            } else if (
              error.error.errorCode === 'user-can-sale-only-own-product'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Пользователь может продавать только свои продукты'
              );
              return;
            } else if (
              error.error.errorCode === 'product-sold-return-before-delete'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Продукт продан, сделайте возврат прежде чем удалить товар'
              );
              return;
            } else if (error.error.errorCode === 'client-duty-not-found') {
              this.ntf.showNotification(
                'snackbar-danger',
                'данные о долгов клиента этому магазину не найдены'
              );
              return;
            } else if (error.error.errorCode === 'duty-sum-less-than-request') {
              this.ntf.showNotification(
                'snackbar-danger',
                'общая сумма долга меньше запрашиваемой'
              );
              return;
            } else if (error.error.errorCode === 'provider-not-found') {
              this.ntf.showNotification(
                'snackbar-danger',
                'провайдер не найден'
              );
              return;
            } else if (
              error.error.errorCode === 'product-have-active-request'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'продукт имеет активный запрос'
              );
              return;
            } else if (error.error.errorCode === 'dic-value-not-found') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Запрашиваемое значение справочника не найдено в системе'
              );
              return;
            } else if (error.error.errorCode === 'illegal-argument-exception') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Переданные параметры неправильны'
              );
              return;
            } else if (error.error.errorCode === 'main-store-not_found') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Главный магазин не найден'
              );
              return;
            } else if (error.error.errorCode === 'store-not_found') {
              this.ntf.showNotification('snackbar-danger', 'Магазин не найден');
              return;
            } else if (
              error.error.errorCode === 'main-store-not-send-to-central'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Основной магазин не может передать самому себе'
              );
              return;
            } else if (
              error.error.errorCode === 'balance-transfer-history-not-found'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'История перемещения средств не найден'
              );
              return;
            } else if (error.error.errorCode === 'balance-transfer-canceled') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Передача средств уже был отменен'
              );
              return;
            } else if (error.error.errorCode === 'store-balance-null') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Значение баланса 0'
              );
              return;
            } else if (
              error.error.errorCode === 'user-can-return-only-own-product'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Пользователь может делать возврат только свои продукты'
              );
              return;
            } else if (error.error.errorCode === 'store-duty-own-error') {
              this.ntf.showNotification(
                'snackbar-danger',
                'В долг можно дать только другим магазинам'
              );
              return;
            } else if (
              error.error.errorCode === 'product-transfer-task-not-found'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Перемещение продукта не найден'
              );
              return;
            } else if (
              error.error.errorCode === 'seller-can-transfer-only-own-product'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Продавец может перемещать только свои продукты'
              );
              return;
            } else if (
              error.error.errorCode === 'product-transfer-status-new'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                'Перемещаемый продукт не должен быть проданным'
              );
              return;
            } else if (error.error.errorCode === 'product-transfer-error') {
              this.ntf.showNotification(
                'snackbar-danger',
                'Ошибка в перемещения товара связано с правами'
              );
              return;
            } else if (
              error.error.errorCode === 'product-imei-not-sold-exists'
            ) {
              this.ntf.showNotification(
                'snackbar-danger',
                error.error.errorMessage
              );
              return;
            } else {
              this.ntf.showNotification('snackbar-danger', 'Ошибка');
            }
          } else if (!error.error) {
            this.ntf.showNotification(
              'snackbar-danger',
              'Не правильный запрос с сервера'
            );
            return;
          } else {
            this.ntf.showNotification('snackbar-danger', 'Ошибка');
          }
        } else {
          this.ntf.showNotification('snackbar-danger', 'Ошибка');
          return;
        }
        return throwError(error);
      })
    );
  }
}
