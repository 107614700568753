import { Injectable } from '@angular/core';
import { Authenticate, Token, User } from 'src/app/admin/model/user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/api.config';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User = new User();

  getUser(): User {
    return this.user;
  }

  isLoginDisable: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private ntf: NotificationService // private toastr: ToastrService
  ) {}

  signin(val: Authenticate) {
    const headers = {
      Authorization: 'Basic ' + btoa('oncharge:ensi'),
      'Content-type': 'application/x-www-form-urlencoded',
    };
    const body = new HttpParams()
      .set('username', val.username)
      .set('password', val.password)
      .set('grant_type', 'password');
    this.http
      .post(`${ApiConfig.api}/oauth/token`, body.toString(), { headers })
      .subscribe({
        next: (token: any) => {
          localStorage.setItem('access_token', token.access_token);
          localStorage.setItem('refresh_token', token.refresh_token);
          localStorage.setItem('username', val.username);
          this.ntf.showNotification('snackbar-success', 'Успешно авторизован');
          this.isLoginDisable = false;
          this.spinner.show();
          setTimeout(() => {
            this.router.navigate(['/dashboard/main']);
          }, 500);
        },
        error: (e) => {
          console.error(e);
          this.spinner.hide();
          this.ntf.showNotification(
            'snackbar-danger',
            'Введен неверный логин или пароль'
          );
          this.isLoginDisable = false;
        },
      });
  }

  // getCurrentUser() {
  //   this.http.get(`${ApiConfig.api}/user/`).subscribe({
  //     next: (user: User) => {
  //       if (user) {
  //         this.user.setValue(user);
  //         console.log(this.user);
  //       }
  //     },
  //     error: (e) => {
  //       console.error(e);
  //     },
  //   });
  // }

  logout() {
    // localStorage.clear();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
    this.router.navigate(['/authentication/signin']);
  }
}
