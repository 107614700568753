<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="header">
            <h2><strong>Пользователи</strong></h2>
          </div>
          <div class="body">
            <div style="text-align: right">
              <button
                mat-raised-button
                color="primary"
                (click)="openDialogAdd(add)"
              >
                Добавить нового пользователя
              </button>
            </div>
            <div class="body table-responsive">
              <table mat-table [dataSource]="dataSource" class="w-100">
                <ng-container matColumnDef="fio">
                  <th mat-header-cell *matHeaderCellDef>ФИО</th>
                  <td mat-cell *matCellDef="let element">{{ element.fio }}</td>
                </ng-container>

                <ng-container matColumnDef="login">
                  <th mat-header-cell *matHeaderCellDef>Логин</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.login }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef>Роль</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.role.nameRu }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="telephone">
                  <th mat-header-cell *matHeaderCellDef>Номер телефона</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.telephone }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="isActive">
                  <th mat-header-cell *matHeaderCellDef>Статус</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.isActive ? 'Активный' : 'Заблокирован' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="store">
                  <th mat-header-cell *matHeaderCellDef>Магазин</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.store?.storeName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.email }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef>Адресс</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.address }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    style="text-align: center; width: 85px"
                  >
                    <a class="icon_delete">
                      <i
                        class="fas fa-trash-alt"
                        matTooltip="Удалить"
                        (click)="removeUser(element)"
                      >
                      </i>
                    </a>
                    <a style="margin-left: 20px" class="icon_add">
                      <i
                        class="fas fa-pencil-alt"
                        matTooltip="Изменить"
                        (click)="openDialogAdd(add, element)"
                      >
                      </i>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #add>
  <div class="dlg_title">
    <h3 *ngIf="!isEditAccess" class="unselectable">Добавление пользователя</h3>
    <h3 *ngIf="isEditAccess" class="unselectable">Изменение пользователя</h3>
  </div>
  <form class="dialog_form" [formGroup]="form">
    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Фамилия </mat-label>
      <input
        matInput
        autocomplete="off"
        formControlName="lastName"
        required
        appearance="outline"
      />
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Имя </mat-label>
      <input matInput autocomplete="off" formControlName="firstName" required />
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Отчество </mat-label>
      <input matInput autocomplete="off" formControlName="middleName" />
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Роль </mat-label>
      <mat-select
        required
        formControlName="role"
        [compareWith]="compareCategoryObjects"
      >
        <mat-option *ngFor="let role of roles" [value]="role">
          {{ role.nameRu }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Логин </mat-label>
      <input matInput autocomplete="off" required formControlName="login" />
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Адресс </mat-label>
      <input matInput autocomplete="off" required formControlName="address" />
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Номер телефона </mat-label>
      <input
        matInput
        placeholder="8 (000) 000 00 00"
        mask="0(000) 000 00 00"
        autocomplete="off"
        required
        formControlName="telephone"
      />
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> E-mail </mat-label>
      <input matInput formControlName="email" autocomplete="off" required />
      <mat-error
        *ngIf="form.controls.email.invalid && form.controls.email.touched"
      >
        <span *ngIf="form.controls.email.errors?.required">
          Email не может быть пустым
        </span>
        <span *ngIf="form.controls.email.errors?.email">
          Введите корректный email
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Статус </mat-label>
      <mat-select
        required
        formControlName="isActive"
        [compareWith]="compareCategoryObjects"
      >
        <mat-option *ngFor="let status of isActive" [value]="status">
          {{ status.nameRu }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Магазин </mat-label>
      <mat-select
        required
        formControlName="store"
        [compareWith]="compareCategoryObjects"
      >
        <mat-option *ngFor="let store of stores" [value]="store">
          {{ store.storeName }}
          <i
            *ngIf="store.isMain"
            class="fas fa-star col-orange"
            matTooltip="Основной магазин"
          ></i>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Пароль </mat-label>
      <input
        matInput
        type="password"
        autocomplete="off"
        required
        formControlName="newPassword"
      />
      <mat-error
        *ngIf="
          form.controls.newPassword.touched && form.controls.newPassword.invalid
        "
        class="validation"
      >
        <span *ngIf="form.controls.newPassword.errors?.required">
          Введите пароль
        </span>
        <span *ngIf="form.controls.newPassword.errors?.minlength">
          Пароль должен быть не менее
          {{ form.controls.newPassword.errors?.minlength.requiredLength }}
          символов. Сейчас он
          {{ form.controls.newPassword.errors?.minlength.actualLength }}.
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="mat_input" appearance="outline">
      <mat-label> Подтверждение </mat-label>
      <input
        matInput
        type="password"
        autocomplete="off"
        required
        formControlName="newPassword2"
      />
      <mat-error
        *ngIf="
          form.controls.newPassword2.touched &&
          form.controls.newPassword2.invalid
        "
        class="validation"
      >
        <span *ngIf="form.controls.newPassword2.errors?.required">
          Введите пароль
        </span>
        <span *ngIf="form.controls.newPassword2.errors?.minlength">
          Пароль должен быть не менее
          {{ form.controls.newPassword2.errors?.minlength.requiredLength }}
          символов. Сейчас он
          {{ form.controls.newPassword2.errors?.minlength.actualLength }}.
        </span>
      </mat-error>
    </mat-form-field>
  </form>

  <div class="btn_block" style="margin-top: 20px">
    <button mat-button [mat-dialog-close] (click)="this.form.reset()">
      Отмена
    </button>
    <button
      *ngIf="!isEditAccess"
      mat-raised-button
      color="primary"
      (click)="save()"
    >
      Сохранить
    </button>
    <button
      *ngIf="isEditAccess"
      mat-raised-button
      color="primary"
      (click)="edit()"
    >
      Изменить
    </button>
  </div>
</ng-template>
