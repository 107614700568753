import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../api.config';
import { Dictionary, ExchangeRate } from '../model/product';
import { Person, Store } from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  currentCourse: ExchangeRate = {
    id: 0,
    actualDate: '',
    value: '',
    currency: new Dictionary(),
  };

  USD: { code: string; id: number } = {
    code: '',
    id: 0,
  };

  constructor(private http: HttpClient) {}

  getAllUsers(): Observable<any> {
    return this.http.get(`${ApiConfig.api}/user/allUsers`);
  }

  getAllDictionary(type: string): Observable<any> {
    return this.http.get(`${ApiConfig.api}/dictionary/allDicts?type=${type}`);
  }

  getAllStores(): Observable<any> {
    return this.http.get(`${ApiConfig.api}/store/allStores`);
  }

  addUser(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/user`, body);
  }

  editUser(body: any): Observable<any> {
    return this.http.put(`${ApiConfig.api}/user`, body);
  }

  getAllPerson(type: string): Observable<any> {
    return this.http.get(`${ApiConfig.api}/person/allPersons?type=${type}`);
  }

  removeUser(id: number): Observable<any> {
    return this.http.delete(`${ApiConfig.api}/user/${id}`);
  }

  addPerson(type: string, body: Person): Observable<any> {
    return this.http.post(`${ApiConfig.api}/person?type=${type}`, body);
  }

  editPerson(type: string, body: Person): Observable<any> {
    return this.http.put(`${ApiConfig.api}/person?type=${type}`, body);
  }

  removePersonById(id: number, type: string): Observable<any> {
    return this.http.delete(`${ApiConfig.api}/person/${id}?type=${type}`);
  }

  removeStoreById(id: number): Observable<any> {
    return this.http.delete(`${ApiConfig.api}/store/${id}`);
  }

  addStore(body: Store): Observable<any> {
    return this.http.post(`${ApiConfig.api}/store`, body);
  }

  editStore(body: Store): Observable<any> {
    return this.http.put(`${ApiConfig.api}/store`, body);
  }

  getAllDictionaryByReferenceId(id: number, type: string): Observable<any> {
    return this.http.get(
      `${ApiConfig.api}/dictionary/allDictsByReference/${id}?type=${type}`
    );
  }

  filterProductByImei(imei: string): Observable<any> {
    return this.http.get(`${ApiConfig.api}/product/find-by-imei/${imei}`);
  }

  filterDetailProduct(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/product/filter-detail?page=${page}&size=${size}&sort=model,asc`,
      body
    );
  }

  filterProduct(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/product/filter?page=${page}&size=${size}&sort=id,desc`,
      body
    );
  }

  sale(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/sale/sale`, body);
  }

  getSaleByOrder(orderNum: Number): Observable<any> {
    return this.http.get(`${ApiConfig.api}/sale/${orderNum}`);
  }

  situationBusiness(params: any = {}): Observable<any> {
    return this.http.post(`${ApiConfig.api}/report/situation-business`, params);
  }

  getCurrentCource(id: number): Observable<any> {
    return this.http.get(`${ApiConfig.api}/currency-course/actual/${id}`);
  }

  saveCurrencyCoucse(body: any): Observable<any> {
    return this.http.post(`${ApiConfig.api}/currency-course`, body);
  }

  getBalansByStoreId(id: number): Observable<any> {
    return this.http.get(`${ApiConfig.api}/balance/${id}`);
  }

  getBalanceCurrentUser(): Observable<any> {
    return this.http.get(`${ApiConfig.api}/balance/`);
  }

  balanceSendCentralStore(
    storeId: number,
    balance: number,
    description: string
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/balance/send-to-central-store/${storeId}/${balance}?description=${description}`,
      null
    );
  }

  givingDebtClients(
    storeId: number,
    clientId: number,
    balance: number,
    description: string
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/duty/give-duty-to-client/${storeId}/${clientId}/${balance}?description=${description}`,
      null
    );
  }

  givingDebtOtherStores(
    storeId: number,
    balance: number,
    description: string
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/duty/give-duty-to-store/${storeId}/${balance}?description=${description}`,
      null
    );
  }

  addCash(
    storeId: number,
    balance: number,
    description: string
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/balance/add-cash/${storeId}/${balance}?description=${description}`,
      null
    );
  }

  cancelTransfer(transferId: number): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/balance/cancel-transfer/${transferId}`,
      null
    );
  }

  balanceWithdraw(
    storeId: number,
    balance: number,
    description: string
  ): Observable<any> {
    return this.http.put(
      `${ApiConfig.api}/balance/withdraw/${storeId}/${balance}?description=${description}`,
      null
    );
  }

  getBalansHistoryByStore(id: number): Observable<any> {
    return this.http.get(`${ApiConfig.api}/balance/history/${id}`);
  }

  getBalanceHistory(): Observable<any> {
    return this.http.get(`${ApiConfig.api}/balance/history`);
  }

  filterBalanceHistory(body: any, page: number, size: number): Observable<any> {
    return this.http.post(
      `${ApiConfig.api}/balance/filter-history?page=${page}&size=${size}&sort=createdDate,desc`,
      body
    );
  }
}
