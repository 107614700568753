import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { fakeBackendProvider } from './core/interceptor/fake-backend';
import { ClickOutsideModule } from 'ng-click-outside';
import { WINDOW_PROVIDERS } from './core/service/window.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefreshTokenInterceptor } from './authentication/service/TokenInterceptor';

import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { AppComponent } from './app.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { UsersComponent } from './components/users/users.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ClickOutsideModule,
    CoreModule,
    SharedModule,
  ],
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AppComponent,
    PageLoaderComponent,
    AuthLayoutComponent,
    UsersComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    fakeBackendProvider,
    WINDOW_PROVIDERS,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
