export class Authenticate {
  username: string = '';
  password: string = '';
}

export interface Token {
  access_token: string;
  token_type?: string;
  refresh_token: string;
  expires_in?: number;
  scope?: string;
  username: string;
}

export class User {
  address?: string | null = '';
  email: string | null = '';
  firstName: string | null = '';
  id?: number | null = null;
  isActive: boolean | null = null;
  lastName: string | null = '';
  phone?: string | null = '';
  middleName: string | null = '';
  newPassword: string | null = '';
  login: string | null = '';
  role: Role = new Role();
  telephone: string | null = '';
  fio: string | null = '';
  store: Store | null = new Store();

  setValue(user: User) {
    this.address = user.address || null;
    this.email = user.email || null;
    this.firstName = user.firstName || null;
    this.id = user.id || null;
    this.isActive = user.isActive || null;
    this.lastName = user.lastName || null;
    this.phone = user.phone || null;
    this.middleName = user.middleName || null;
    this.newPassword = user.newPassword || null;
    this.login = user.login || null;
    this.role = user.role || null;
    this.telephone = user.telephone || null;
    this.fio = user.fio || null;
    this.store = user.store || null;
  }
}

export class Role {
  code: string = '';
  id?: number | null = null;
  nameEn?: string = '';
  nameKz?: string = '';
  nameQq?: string = '';
  nameRu: string = '';
  referenceId?: number | null = null;
}

export class Store {
  address: string = '';
  id: number | null = null;
  storeName: string = '';
  telephone: string = '';
  balance: number = null;
}

export class Person {
  address: string = '';
  email: string = '';
  firstName: string = '';
  id: number | null = null;
  lastName: string = '';
  middleName: string = '';
  telephone: string = '';
  fullName: string = '';
  type?: { id: number | null; name: string; type: string } = {
    id: null,
    name: '',
    type: '',
  };
}

export class Balans {
  store: Store | null;
  cashBalance: number | null;
  dutyBalance: number | null;
}
