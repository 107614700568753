import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/admin/model/user';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthService } from '../../authentication/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  user: User = new User();

  constructor(
    public router: Router,
    private authService: AuthService,
    private ntf: NotificationService
  ) {
    this.user = this.authService.getUser();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      if (this.user) {
        if (
          this.user.role.code === 'ADMIN' ||
          this.user.role.code === 'SUPER_ADMIN'
        ) {
          return true;
        } else {
          if (this.user.role.code === 'SELLER') {
            this.ntf.showNotification(
              'snackbar-info',
              'Страница доступна только админам!'
            );
          }
          this.router.navigate(['/cash-office/products']);
          return false;
        }
      } else {
        this.router.navigate(['/signin']);
        return false;
      }
    } else {
      this.router.navigate(['/signin']);
      return false;
    }
  }
}
