<nav #navbar class="navbar">
  <div class="container-fluid">
    <div class="navbar-header">
      <a
        class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false"
      ></a>
      <a
        class="bars"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
      ></a>
      <a class="navbar-brand">
        <!-- <img src="assets/images/logo.jpeg" alt="" width="75%" /> -->
        <span class="logo-name">On charge</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button
            mat-icon-button
            (click)="callSidemenuCollapse()"
            class="sidemenu-collapse"
          >
            <i class="nav-hdr-btn ti-align-left"></i>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button
            mat-icon-button
            (click)="callFullscreen()"
            class="nav-notification-icons"
          >
            <i class="nav-hdr-btn ti-fullscreen"></i>
          </button>
        </li>

        <li class="nav-item user_profile" ngbDropdown>
          <a
            ngbDropdownToggle
            role="button"
            class="nav-notification-icons pt-0"
          >
            <!-- {{ user.lastName + ' ' + user.firstName }} -->
            {{ user.login }}
            <!-- <img
              src="assets/images/user.jpg"
              class="rounded-circle"
              width="32"
              height="32"
              alt="User"
            /> -->
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div
              class="noti-list"
              style="position: relative; max-width: 600px; max-height: 300px"
            >
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a [routerLink]="['/dashboard/main']"> Статистика </a>
                    </li>
                    <li>
                      <a [routerLink]="['/cash-office/sale']"> Касса </a>
                    </li>
                    <li>
                      <a [routerLink]="['/admin/warehouse-search']"> Склад </a>
                    </li>
                    <li>
                      <a (click)="logout()"> Выход </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
>
