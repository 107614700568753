import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Меню',
    moduleName: '',
    icon: '',
    class: 'header',
    groupTitle: true,
    submenu: [],
  },
  {
    path: '/dashboard/main',
    title: 'Статистика',
    moduleName: 'dashboard',
    icon: 'bar-chart',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Касса',
    moduleName: 'cash-office',
    icon: 'shopping-cart',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/cash-office/products',
        title: 'Товары',
        moduleName: 'cash-office',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/cash-office/sale',
        title: 'Продажа товара',
        moduleName: 'cash-office',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/cash-office/orders',
        title: 'Заказы',
        moduleName: 'cash-office',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/cash-office/return-pro',
        title: 'Возврат товара',
        moduleName: 'cash-office',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/cash-office/moving-pro',
        title: 'Перемещение товара',
        moduleName: 'cash-office',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/cash-office/moving-pro-seller',
        title: 'Перемещение / потверждение',
        moduleName: 'cash-office',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Долги',
    moduleName: 'debts',
    icon: 'layout',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/debts/debt-customers',
        title: 'Долги клиентов',
        moduleName: 'debts',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/debts/debt-suppliers',
        title: 'Долги поставщикам',
        moduleName: 'debts',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/debts/debt-stores',
        title: 'Долги магазинов',
        moduleName: 'debts',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Ревизия',
    moduleName: 'revision',
    icon: 'check-circle',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/revision/revision-product',
        title: 'Ревизия товара',
        moduleName: 'revision',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/revision/revision-balance',
        title: 'Ревизия баланса',
        moduleName: 'revision',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Баланс',
    moduleName: 'balance',
    icon: 'copy',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/balance/balancing',
        title: 'Управление средствами',
        moduleName: 'balance',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/balance/balance-history',
        title: 'История упаравления балансами',
        moduleName: 'balance',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Склад',
    moduleName: 'admin',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: '/admin/add-product',
        title: 'Добавить товар',
        moduleName: 'admin',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/admin/warehouse-search',
        title: 'Поиск товара',
        moduleName: 'admin',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/admin/product-posting',
        title: 'История приемки',
        moduleName: 'admin',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/admin/report',
        title: 'Отчеты',
        moduleName: 'admin',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/admin/store',
        title: 'Управление магазинами',
        moduleName: 'admin',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
      {
        path: '/admin/person',
        title: 'Управление контрагентами',
        moduleName: 'admin',
        icon: '',
        class: '',
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/users',
    title: 'Пользователи',
    moduleName: 'users',
    icon: 'user-check',
    class: '',
    groupTitle: false,
    submenu: [],
  },
];
