import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Role, Store, User } from 'src/app/admin/model/user';
import { AdminService } from 'src/app/admin/services/admin.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = [
    'fio',
    'login',
    'role',
    'telephone',
    'email',
    'isActive',
    'store',
    'address',
    'delete',
  ];

  dataSource = new MatTableDataSource();

  form: FormGroup;

  roles: Role[] = [];
  stores: Store[] = [];

  isEditAccess: boolean = false;

  isActive = [
    { nameRu: 'Активный', id: 1 },
    { nameRu: 'Заблокирован', id: 2 },
  ];

  constructor(
    private matDialog: MatDialog,
    private ntf: NotificationService,
    private adminService: AdminService
  ) {
    this.form = this.initForm();
  }

  ngOnInit() {
    this.getAllUsers();
    this.getAllRoles();
    this.getAllStores();
  }

  initForm(user?: User) {
    if (user == null) {
      return new FormGroup({
        lastName: new FormControl(''),
        firstName: new FormControl(''),
        middleName: new FormControl(''),
        // fio: new FormControl(''),
        telephone: new FormControl(''),
        role: new FormControl(''),
        address: new FormControl(''),
        isActive: new FormControl(''),
        login: new FormControl(''),
        store: new FormControl(''),
        email: new FormControl('', [Validators.required, Validators.email]),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        newPassword2: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
      });
    } else {
      return (this.form = new FormGroup({
        id: new FormControl(user.id),
        lastName: new FormControl(user.lastName),
        firstName: new FormControl(user.firstName),
        middleName: new FormControl(user.middleName),
        // fio: new FormControl(''),
        telephone: new FormControl(user.telephone),
        role: new FormControl(user.role),
        store: new FormControl(user.store),
        address: new FormControl(user.address),
        isActive: new FormControl(
          user.isActive
            ? { nameRu: 'Активный', id: 1 }
            : { nameRu: 'Заблокирован', id: 2 }
        ),
        login: new FormControl(user.login),
        email: new FormControl(user.email, [
          Validators.required,
          Validators.email,
        ]),
        newPassword: new FormControl(user.newPassword, [
          Validators.required,
          Validators.minLength(6),
        ]),
        newPassword2: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
      }));
    }
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  openDialogAdd(modal: any, user?: User) {
    if (user) {
      this.initForm(user);
      this.isEditAccess = true;
    } else {
      this.form.addControl(
        'newPassword2',
        new FormControl('', [Validators.required, Validators.minLength(6)])
      );
      this.isEditAccess = false;
    }
    this.matDialog.open(modal, {
      maxHeight: '85vh',
      minHeight: '35vh',
      minWidth: '55vw',
      maxWidth: '75vw',
    });
    // .afterClosed()
    // .toPromise()
    // .then(() => {});
  }

  save() {
    if (this.form.invalid) {
      this.ntf.showNotification(
        'snackbar-info',
        'Необходимо заполнить все поля'
      );
      return;
    }
    if (this.form.value.newPassword !== this.form.value.newPassword2) {
      this.ntf.showNotification('snackbar-danger', 'Пароли не совпадают');
      return;
    }

    delete this.form.value.newPassword2;
    let body: User = this.form.value;
    body.isActive = this.form.value.isActive.id === 1 ? true : false;
    // body.telephone = '+7' + this.form.value.telephone;
    this.ntf.showLoader();
    this.adminService.addUser(body).subscribe({
      next: (data) => {
        this.getAllUsers();
        this.matDialog.closeAll();
        this.ntf.isConfirmed('Добавление', 'Успешно добавлен', 'success');
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  edit() {
    if (this.form.invalid) {
      this.ntf.showNotification(
        'snackbar-info',
        'Необходимо заполнить все поля'
      );
      return;
    }
    if (this.form.value.newPassword !== this.form.value.newPassword2) {
      this.ntf.showNotification('snackbar-danger', 'Пароли не совпадают');
      return;
    }

    delete this.form.value.newPassword2;
    let body: User = this.form.value;
    body.isActive = this.form.value.isActive.id === 1 ? true : false;
    this.ntf.showLoader();
    this.adminService.editUser(body).subscribe({
      next: (data) => {
        this.getAllUsers();
        this.matDialog.closeAll();
        this.ntf.isConfirmed('Изменение', 'Успешно изменен', 'success');
        this.ntf.hideLoader();
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  removeUser(user: User) {
    if (!user.id) {
      return;
    }
    if (window.confirm('Удалить безвозвратно?')) {
      this.ntf.showLoader();
      this.adminService.removeUser(user.id).subscribe({
        next: (data) => {
          this.getAllUsers();
          this.ntf.hideLoader();
          this.ntf.isConfirmed(
            'Удаление пользователя',
            'Успешно удален',
            'success'
          );
        },
        error: (e) => {
          this.ntf.hideLoader();
          console.error(e);
        },
      });
    }
  }

  getAllUsers() {
    this.ntf.showLoader();
    this.adminService.getAllUsers().subscribe({
      next: (users) => {
        users.forEach((element: User) => {
          element.fio =
            element.lastName +
            ' ' +
            element.firstName +
            ' ' +
            (element.middleName ?? '');
        });
        this.ntf.hideLoader();
        this.dataSource = new MatTableDataSource(users);
      },
      error: (e) => {
        this.ntf.hideLoader();
        console.error(e);
      },
    });
  }

  getAllRoles() {
    this.adminService.getAllDictionary('ROLE').subscribe({
      next: (roles) => (this.roles = roles),
      error: (e) => console.error(e),
    });
  }

  getAllStores() {
    this.adminService.getAllStores().subscribe({
      next: (data) => (this.stores = data),
      error: (e) => console.error(e),
    });
  }
}
